*, ::before, ::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

html {
    font-family: 'Poppins', sans-serif;
}

body{
    background: #f1f1f1;
    height: 100vh;
}

.container {
    position:absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 660px;
    height: 300px;
    background: #f1f1f1;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0,5);
}

.container-onglets{
    background: #f1f1f1;
    width: 100%;
    height: 30%;
    display: flex;
    border-bottom: 1px solid #333;
}

.onglets{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
    border: 0px;
}

.onglets button{
    border: 0px;
}

.contenu {
    height: 70%;
    width: 100%;
    position: absolute;
    display:none;
}

.contenu input{
    margin: 20px;
    display:block;
}

/* Animation */

.onglets.active{
    background: midnightblue;
    color: #f1f1f1;
    transition: all 0.2s ease;
}

.activeContenu{
    display:contents;
}

p {
    display: contents;
}
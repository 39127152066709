.sportsSection {
    margin: 32px 16px 0px 16px;
}

.sportsContainer {
    display: grid;
    grid-template-columns: repeat(5, 250px);
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
}

.sportsSection h3 {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 24px;
}

.sportsContainer a {
    text-decoration: none;
    color: black;
}

.sportsContainer li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: #18191A;
    height: 128px;
    padding-left: 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.sportsContainer li p {
    font-family: 'Poppins', sans-serif;
    color: #727070;
    text-transform: uppercase;
    font-weight: 700;
}

.sportsContainer li img {
    width: 130px;
    height: 130px;
}

@media (min-width: 1100px){
    .sportsContainer {
        grid-template-columns: repeat(4, 250px);
    }
}

@media (max-width: 1100px){
    .sportsContainer {
        grid-template-columns: repeat(3, 180px);
    }

    .sportsContainer li img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px){
    .sportsContainer {
        grid-template-columns: repeat(2, 160px);
    }

    .sportsContainer li p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }
    

    .sportsContainer li img {
        width: 70px;
        height: 70px;
    }

}

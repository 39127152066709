.sportEventsContainer {
    margin: 32px 16px 0px 16px;
}

.sportEventsContainer h3 {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 24px;
}

.sportEventsContainer ul {
    display: grid;
    grid-template-columns: repeat(1, 70vw);    
    justify-content: center;
    margin-top: 32px;
    gap: 32px;
}

.sportEventsContainer ul a {
    text-decoration: none;
    color: black;
}

.sportEventsContainer ul li {
    padding-left: 16px;
    font-family: "Poppins", sans-serif;
    list-style: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    gap: 32px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: 100px;
    border-radius: 4px;
    font-size: 15.5px;
}

.sportEventsContainer ul li div {
    text-align: center;
}

.sportEventsContainer ul li div:nth-child(1) {
    text-align: center;
    width: 19vw;
}

.sportEventsContainer ul li div:nth-child(1) p:nth-child(1) {
    font-weight: 600;
}

.sportEventsContainer ul li div:nth-child(3) {
    position: absolute;
    right: 0;
    width: 48%;
    height: 100%;
}

.sportEventsContainer ul li div:nth-child(3) img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

